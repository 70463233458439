// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';

// Components
import SEO from 'components/seo';
import Layout from 'components/layout';
import UnitContent from 'components/units/home/unitContent';
import BreadCrumb from 'components/breadCrumb';
import { device } from 'components/device';

//Styled
const Container = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  @media ${device.laptop} {
    display: block;
    padding: 0 20px;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const Units = ({ page }) => {
  const locationsSP = [
    {
      name: 'Sírio-Libanês Bela Vista',
      location: {
        lat: -23.5575613,
        lng: -46.6545421,
      },
    },
    {
      name: 'Sírio-Libanês Itaim',
      location: {
        lat: -23.5848177,
        lng: -46.674954,
      },
    },
    {
      name: 'Sírio-Libanês Jardins',
      location: {
        lat: -23.573371,
        lng: -46.668063,
      },
    },
    {
      name: 'Sírio-Libanês Zona Oeste',
      location: {
        lat: -23.5369773,
        lng: -46.6542809,
      },
    },
    {
      name: 'Sírio-Libanês Zona Sul',
      location: {
        lat: -23.6118703,
        lng: -46.7020446,
      },
    },
  ];

  const locationsBRA = [
    {
      name: 'Sírio-Libanês Brasília',
      location: {
        lat: -15.8351173,
        lng: -47.9117906,
      },
    },
    {
      name: 'Centro de Oncologia Asa Sul',
      location: {
        lat: -15.8343591,
        lng: -47.91225,
      },
    },
    {
      name: 'Centro de Diagnósticos Asa Sul',
      location: {
        lat: -15.8363042,
        lng: -47.9126317,
      },
    },
    {
      name: 'Especialidades Médicas',
      location: {
        lat: -15.83571,
        lng: -47.913124,
      },
    },
    {
      name: 'Unidade Águas Claras',
      location: {
        lat: -15.83151940861746,
        lng: -48.04097354555284,
      },
    },
  ];

  const [scriptReady, setScript] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&language=pt-BR`;
    script.addEventListener('load', () => {
      setScript(true);
    });
    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', () => {
        setScript(false);
      });
    };
  }, []);

  return (
    <Layout>
      <SEO dataSeo={page} />
      <Container>
        <Wrapper>
          <BreadCrumb
            details
            markdown={getMarkdown(page, 'ckhqc2dyg16wd0a24sez02xx8', true)}
          />
        </Wrapper>
        <UnitContent
          markdown={getMarkdown(page, 'ckhq9x53c15a90a24q065278g', true)}
          fragments={getMarkdown(page, 'ckhq9x53c15a90a24q065278g')}
          zoom={11}
          location={locationsSP}
          place="sp"
          defaultCenter={{ lat: -23.566785, lng: -46.668126 }}
          scriptReady={scriptReady}
        />
        <UnitContent
          markdown={getMarkdown(page, 'ckhqcdze8175o0e72yk97fhbj', true)}
          fragments={getMarkdown(page, 'ckhqcdze8175o0e72yk97fhbj')}
          zoom={15}
          location={locationsBRA}
          place="brasilia"
          defaultCenter={{ lat: -15.8350279, lng: -47.9137711 }}
          scriptReady={scriptReady}
        />
      </Container>
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckhq8zt8w14pk0e72cdf9z3op" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Units page={response.gcms.site.pages} />;
      }}
    />
  );
};
