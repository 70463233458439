// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

// Components
import { device } from './device';

// Styles
export const Container = styled.div`
  margin-top: ${props => props.marginTop && '2rem'};
  grid-area: BD;
  height: ${props => props.isportalPaciente && '2.1875rem'};
  width: 100%;
  display: ${props => (props.isHome ? 'none' : 'flex')};
  flex-direction: row;
  align-items: flex-start;
  text-align: start;
  padding-bottom: ${props => props.isEnsinoEPesquisa && '15px'};
  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}

  @media ${device.laptop} {
    display: none;
  }
`;

export const ContentBreadCrumb = styled.div`
  margin: ${props =>
    props.isCDICardio
      ? 'unset'
      : props.details
      ? '1.875rem 0 3.125rem 0'
      : props.marginBreadCrumb
      ? props.marginBreadCrumb
      : '0 0 3.25rem'};

  ol {
    margin: 0;
    display: flex;
    list-style: none;
  }

  li {
    margin-bottom: 0;
    margin-right: 0.5rem;
    width: fit-content;
    color: #8f9194;
    font-size: 0.875rem;

    > a {
      color: #45a7df;
    }

    > a:hover {
      text-decoration: underline;
    }
  }
`;

const BreadCrumb = ({
  details,
  markdown,
  breadCrumb,
  marginTop,
  isHome,
  isCDICardio,
  marginBreadCrumb,
  isTabs = false,
  zIndex,
  isportalPaciente,
  isEnsinoEPesquisa,
  className = '',
}) => {
  const LinkRender = ({ href, children }) => {
    return <Link to={href}>{children}</Link>;
  };
  return (
    <Container
      className={className}
      isEnsinoEPesquisa={isEnsinoEPesquisa}
      marginTop={marginTop}
      isHome={isHome}
      zIndex={zIndex}
      isportalPaciente={isportalPaciente}
    >
      <ContentBreadCrumb
        details={details}
        isCDICardio={isCDICardio}
        marginBreadCrumb={marginBreadCrumb}
      >
        <ReactMarkdown
          renderers={isTabs ? { link: LinkRender } : {}}
          children={markdown || breadCrumb || '-'}
        />
      </ContentBreadCrumb>
    </Container>
  );
};

export default BreadCrumb;
