import styled from 'styled-components';

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;

  > a {
    font-size: 0.875rem;
  }

  > span {
    color: ${props => (props.color ? props.color : '#231f20')};
    margin-left: ${props => (props.marginLeft ? props.marginLeft : '0.875rem')};
    font-weight: ${props => (props.weight ? props.weight : '400')};
  }
`;

export const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
