// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {
  TmpTypography,
  TmpTooltip,
  TmpIcon,
  TmpLink,
  TmpDivider,
} from '@tempo/tempo-design-system-core/dist';
import { check as Check, info as Info } from '@tempo/tempo-assets/dist';

// Images
import brasiliaMapImage from 'images/mapsImages/brasilia-home3.png';
import spMapImage from 'images/mapsImages/sp-home3.png';

// Components
import { device } from '../../device';
import RenderMaps from './maps';

import { LinkContent, Icon } from './styles';

const Container = styled.section`
  grid-column: 2 / -2;

  > ul {
    margin: 1.875rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1 / -1;
    column-gap: 1.875rem;
    list-style: none;

    @media ${device.laptop} {
      margin-top: 0.625rem;
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.tablet} {
      grid-template-columns: repeat(1, 1fr);
    }

    li {
      padding: 1.875rem;
      margin-bottom: 2.5rem;
      border: 1px solid #c6c8cc;
      border-radius: 0.5rem;

      @media ${device.laptop} {
        padding: 1.25rem;
      }

      a {
        color: #2c6ef2;
      }

      > h2 {
        font-size: 1.5rem;

        ${'' /* waze button */}
        > a {
          padding: 0.625rem;
          width: 2.75rem;
          display: none;
          align-items: center;
          border-radius: 50%;
          border: 1px solid #c6c8cc;

          @media ${device.laptop} {
            display: flex;
          }
        }
        ${'' /* waze button */}

        @media ${device.laptop} {
          font-size: 1.125rem;
          display: flex;
          justify-content: space-between;
        }
      }
      > p {
        margin-bottom: 1.875rem;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #231f20;
        width: 19.875rem;

        > strong {
          font-size: 0.875rem;
          font-weight: 400;
          color: #8f9194;
          display: block;
          margin-top: 0.625rem;

          @media ${device.laptop} {
            margin-top: 0;
          }
        }
      }

      > span {
        margin-bottom: 1rem;
      }

      > ul {
        padding: 0;
        margin: 1.5rem 0 0;
        list-style: none;

        > li {
          margin-bottom: 0.625rem;
          padding: 0;
          align-items: center;
          display: flex;
          border: none;
          font-size: 0.875rem;
          color: #231f20;
          line-height: 1.5rem;

          @media ${device.tablet} {
            margin-bottom: 1.25rem;
          }

          > img {
            width: 1rem;
            margin-left: 0.75rem;
          }

          &:last-child {
            position: absolute;
            bottom: 1.875rem;
            margin: 0;
            align-items: center;
            display: flex;

            @media ${device.laptop} {
              margin-top: 22px;
              position: static;
              width: 100%;
            }

            > img {
              margin-top: 1px;
            }
          }
        }
      }
    }

    > li {
      position: relative;
      width: 23.125rem;
      height: 53.125rem;

      @media ${device.laptop} {
        margin-bottom: 1.25rem;
        padding-bottom: 1.5625rem;
        width: 100%;
        height: unset;
        border-radius: 0.5rem;
      }
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 3.125rem;
  display: ${props => (props.isHome ? 'none' : 'flex')};
  font-size: 1.5rem;
  font-weight: 400;

  @media ${device.laptop} {
    margin: 1.9375rem 0 1.875rem 0;
    width: 53%;
    font-size: 1.125rem;
  }
`;

const MapSpace = styled.div`
  grid-area: MAP;
  grid-column: 1 / span 4;
  display: ${props => props.isHome && 'none'};
  width: 100%;
  height: 22.5rem;
  background-image: url(${props => props.mapImage && props.mapImage});
  background-size: cover;
  border-radius: 8px;
  background-repeat: no-repeat;

  @media ${device.laptop} {
    width: 100%;
    height: 14.6875rem;
    margin-bottom: 1.25rem;
    background-position: center;
    background-size: cover;
  }

  @media ${device.tablet} {
    background-position-y: 0;
  }

  @media ${device.mobile} {
    background-image: unset;
    background-position-y: unset;
    background-position-x: -85px;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 17.375rem;
  height: 2.9375rem;
  padding: 0.5rem 0.875rem;
  background-color: #c5d7fb;
  border-radius: 500px;
  font-size: 0.75rem;
  line-heigth: 0.9375rem;

  > p {
    width: 100%;
    max-width: 11.625rem;
  }
`;

const ContainerDivider = styled.div`
  margin: 30px 0;
`;

const ContainerTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`;

const UnitContent = ({
  defaultCenter,
  markdown,
  fragments,
  location,
  zoom,
  scriptReady,
  place,
  isHome,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const { singletexts } = fragments && fragments[0];
  const isWindow = typeof window !== 'undefined';

  const handleMapImage = place => {
    switch (place) {
      case 'sp':
        return spMapImage;
      case 'brasilia':
        return brasiliaMapImage;
      default:
        return spMapImage;
    }
  };

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  const renderers = {
    blockquote: ({ children }) =>
      isWindow && { TmpTooltip } && (
        <TmpTooltip
          message="Saúde Populacional é o programa que atende beneficiários de empresas e operadoras de saúde parceiras."
          position="bottom-end"
        >
          <TooltipContent>
            {isWindow && { TmpIcon } && (
              <>
                <TmpIcon icon={<Check />} size="sm" />
                {children}
                <TmpIcon icon={<Info />} size="sm" />
              </>
            )}
          </TooltipContent>
        </TmpTooltip>
      ),
    h2: ({ children }) =>
      isWindow && { TmpTypography } && (
        <TmpTypography component="heading">{children}</TmpTypography>
      ),
    code: ({ language, value }) => {
      if (language === 'TmpLink') {
        const props = JSON.parse(value);
        return (
          isWindow && { TmpLink } && (
            <TmpLink
              href={props.href}
              icon={props.icon || false}
              marginTop={props.marginTop || 0}
              marginBottom={props.marginBottom || 0}
              marginLeft={props.marginLeft || 0}
              marginRight={props.marginRight || 0}
              color={props.color || '#2c6ef2'}
              display={props.display || 'inline-flex'}
              disabled={props.disabled || false}
            >
              {props.children}
            </TmpLink>
          )
        );
      } else if (language === 'TmpLinkWithIcon') {
        const props = JSON.parse(value);
        return (
          isWindow && { TmpLink } && (
            <LinkContent color={props.color} weight={props.weight}>
              <Icon src={props.imgSrc} alt={props.imgAlt} />
              {props.href ? (
                <TmpLink
                  href={props.href}
                  icon={props.icon || false}
                  marginTop={props.marginTop || 0}
                  marginBottom={props.marginBottom || 0}
                  marginLeft={props.marginLeft || 0}
                  marginRight={props.marginRight || 0}
                  color={props.color || '#2c6ef2'}
                  display={props.display || 'inline-flex'}
                  disabled={props.disabled || false}
                >
                  {props.children}
                </TmpLink>
              ) : (
                <TmpTypography component="caption">
                  {props.children}
                </TmpTypography>
              )}
            </LinkContent>
          )
        );
      } else if (language === 'TmpDivider') {
        return (
          isWindow && { TmpDivider } && (
            <ContainerDivider>
              <TmpDivider />
            </ContainerDivider>
          )
        );
      } else if (language === 'TmpTooltip') {
        const props = JSON.parse(value);
        return (
          isWindow && { TmpTooltip, TmpIcon, TmpTypography } && (
            <ContainerTooltip>
              <Icon src={props.imgSrc} alt={props.imgAlt} />
              <TmpTooltip message={props.message} position={props.position}>
                <LinkContent color={props.color} weight={props.weight}>
                  <TmpTypography component="caption">
                    {props.children}
                  </TmpTypography>
                  <TmpIcon icon={<Info />} size="sm" />
                </LinkContent>
              </TmpTooltip>
            </ContainerTooltip>
          )
        );
      } else {
        return <div />;
      }
    },
  };

  return (
    <Container>
      <Title isHome={isHome}>{singletexts[0]}</Title>
      <MapSpace mapImage={handleMapImage(place)} isHome={isHome}>
        {isMobile && (
          <RenderMaps
            isMap
            zoom={zoom}
            defaultCenter={defaultCenter}
            location={location}
            scriptReady={scriptReady}
          />
        )}
      </MapSpace>
      <ReactMarkdown
        renderers={renderers}
        children={markdown}
        allowDangerousHtml
      />
    </Container>
  );
};
export default UnitContent;
